import React, { useEffect, useState } from "react";
import { graphql, StaticQueryDocument } from "gatsby";
import "../styles/index.styles.scss";
import "../styles/general.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import VideoComponent from "../components/VideoComponent/VideoComponent";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import ButtonComponent from "../components/ButtonComponent/ButtonComponent";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";
import BoxComponent from "../components/BoxComponent/BoxComponent";
import { GatsbyImageProps, StaticImage } from "gatsby-plugin-image";

import AusdauerIcon from "../assets/svg/ausdauer.svg";
import BurnoutIcon from "../assets/svg/burnout.svg";
import FocusIcon from "../assets/svg/focus.svg";
import FoodIcon from "../assets/svg/food.svg";
import HealingIcon from "../assets/svg/healing.svg";
import SmogIcon from "../assets/svg/smog.svg";
import CreativityIcon from "../assets/svg/creativity.svg";
import PerformanceIcon from "../assets/svg/performance.svg";
import MuscleIcon from "../assets/svg/muscle.svg";
import FreeIcon from "../assets/svg/free.svg";
import PainIcon from "../assets/svg/pain.svg";
import WaterIcon from "../assets/svg/water.svg";

import PosterImageIntro from "../assets/images/intro_poster.png";
import KlicktippComponent from "../components/KlicktippComponent/KlicktippComponent";
import SeperatorComponent from "../components/SeperatorComponent/SeperatorComponent";
import { useWindowSize } from "../utils/useWindowSize";
import { CircleConfig } from "../components/ContentRowComponent/ContentRowComponent.types";
import BackgroundImage from "gatsby-background-image";

interface IndexPageProps {
  data: {
    medicineParallaxImage: {
      childImageSharp: {
        fluid: GatsbyImageProps;
      };
    };
    homeParallaxImage: {
      childImageSharp: {
        fluid: GatsbyImageProps;
      };
    };
    jobParallaxImage: {
      childImageSharp: {
        fluid: GatsbyImageProps;
      };
    };
    sportParallaxImage: {
      childImageSharp: {
        fluid: GatsbyImageProps;
      };
    };
  };
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  /**
   *
   */
  const medicineBgImageData = data.medicineParallaxImage.childImageSharp.fluid;
  const homeBgImageData = data.homeParallaxImage.childImageSharp.fluid;
  const jobBgImageData = data.jobParallaxImage.childImageSharp.fluid;
  const sportBgImageData = data.sportParallaxImage.childImageSharp.fluid;

  /**
   *
   */
  const [circleConfigs, setCircleConfigs] = useState<CircleConfig[]>([
    {
      color: "#f89a26",
      radius: 3500,
      weight: 8,
      top: -1000,
      horiz: -800,
      active: true,
    },
    {
      color: "#e9e9e9",
      radius: 3000,
      weight: 8,
      left: true,
      top: -950,
      horiz: -750,
      active: true,
    },
    {
      color: "#f89a26",
      radius: 2500,
      weight: 9,
      horiz: -850,
      top: -650,
      active: true,
    },
    {
      radius: 2300,
      weight: 8,
      horiz: -800,
      left: true,
      top: -400,
      color: "#F89B06",
      active: true,
    },
  ]);

  /**
   *
   */
  useEffect(() => {
    switch (true) {
      case width > 769:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 3500,
            weight: 8,
            top: -1000,
            horiz: -800,
            active: true,
          },
          {
            color: "#e9e9e9",
            radius: 3000,
            weight: 8,
            left: true,
            top: -950,
            horiz: -750,
            active: true,
          },
          {
            color: "#f89a26",
            radius: 2500,
            weight: 9,
            horiz: -850,
            top: -650,
            active: true,
          },
          {
            radius: 2300,
            weight: 8,
            horiz: -800,
            left: true,
            top: -400,
            color: "#F89B06",
            active: true,
          },
        ]);
        break;

      case width <= 769:
      default:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 900,
            weight: 8,
            top: 90,
            horiz: -250,
            active: true,
          },
          {
            color: "#e9e9e9",
            radius: 800,
            weight: 8,
            left: true,
            top: -280,
            horiz: -250,
            active: true,
          },
          {
            active: false,
          },
          {
            active: false,
          },
        ]);
        break;
    }
  }, [width]);

  return (
    <LayoutComponent seo={{ title: t("page.index.title") }}>
      <div className="relative-parent">
        <VideoComponent
          minHeight={500}
          src="/video/Startseite_6MB.mp4"
          poster={PosterImageIntro}
        />
        <div className="title-video-box-wrapper">
          <div className="title-video-box-wrapper--title">
            Wir überwinden die Grenzen der Natur&shy;wissenschaften.
          </div>
          <ButtonComponent title="Komm doch mit" theme="outline" invers />
        </div>
      </div>

      <ContentRowComponent circleConfig={circleConfigs[0]}>
        <div className="content-row-box-wrapper">
          <BoxComponent borderColor="#f89a26">
            <div className="box-title">Neu bei uns?</div>
            <div className="flex-grow-1 box-text">
              Wir sind spezialisiert auf eine Frequenztechnologie, die auf der
              Nutzung natürlicher, ursprünglicher Quantenenergie basiert.
            </div>
            <ButtonComponent
              theme="intro"
              title="Zeig mir, um was es geht!"
              invers
            />
          </BoxComponent>

          <BoxComponent borderColor="#f89a26">
            <div className="box-title">Wir kennen uns schon?</div>
            <div className="flex-grow-1 box-text">
              Mit den Produkten und deren Möglichkeiten bist Du bereits vertraut
              und möchtest nachbestellen.
            </div>
            <ButtonComponent
              invers
              theme="intro"
              title="Bring mich zu den Produkten!"
            />
          </BoxComponent>
        </div>
      </ContentRowComponent>

      <ContentRowComponent className="maxed-z-index">
        <h2 className="headline-h2">
          Frequenztechnologie der
          <br />
          nächsten Generation
        </h2>
      </ContentRowComponent>
      <div className="embed-container maxed">
        <iframe
          className="vimeo-video-iframe"
          loading="lazy"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          src="https://player.vimeo.com/video/200701647?autoplay=0&amp;title=0&amp;byline=0&amp;wmode=transparent&amp;autopause=0"
          title={t("home.video.description")}
          allowFullScreen
        />
      </div>

      <ContentRowComponent circleConfig={circleConfigs[1]}>
        <div className="list-products-wrapper">
          <div className="list-title">
            Der 90.10.-CUBE ist das über viele Jahre entwickelte
            Vorzeigeprodukt:
          </div>
          <ul className="list-wrapper">
            <li>Brenne Energie dauerhaft in Materie!</li>
            <li>Schütze Dich effektiv vor Umweltbelastungen!</li>
            <li>Harmonisiere Dein Leben!</li>
          </ul>
        </div>
      </ContentRowComponent>

      <ContentRowComponent className="column-wrapper first-z-index">
        <h2 className="headline-h2">Unzählige Möglichkeiten</h2>
        <SeperatorComponent />
        <BackgroundImage
          className={"text-image-wrapper"}
          fluid={medicineBgImageData as any}
        >
          <div className="filter-element" />
          <div className="content-wrapper">
            <div className="title">Medizin</div>
            <div className="text">
              Die von 90.10. entwickelte Frequenztechnologie fundiert auf zwei
              Kräften:
              <br />
              Unendlich verstärkbare, bio-verfügbare Energie und Frequenzen, die
              mit dieser Energie transportiert werden. Die
              Anwendungsmöglichkeiten in der Medizinforschung erstrecken sich
              auf viele Bereiche.
            </div>
          </div>
        </BackgroundImage>
      </ContentRowComponent>

      <ContentRowComponent circleConfig={circleConfigs[2]}>
        <div className="content-row-box-wrapper">
          <BoxComponent borderColor="#0092B4" icon={<PainIcon />} type="small">
            <div className="box-title small">Schmerz&shy;therapie</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#0092B4"
            />
          </BoxComponent>

          <BoxComponent
            borderColor="#0092B4"
            icon={<HealingIcon />}
            type="small"
          >
            <div className="box-title small">Heilungsbe&shy;schleunigung</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#0092B4"
            />
          </BoxComponent>

          <BoxComponent borderColor="#0092B4" icon={<FreeIcon />} type="small">
            <div className="box-title small">Nebenwirkungs&shy;freiheit</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#0092B4"
            />
          </BoxComponent>
        </div>
      </ContentRowComponent>

      <ContentRowComponent className="column-wrapper">
        <BackgroundImage
          className={"text-image-wrapper"}
          fluid={homeBgImageData as any}
        >
          <div
            className="filter-element"
            style={{ background: "#FFB100 0% 0% no-repeat padding-box" }}
          />
          <div className="content-wrapper">
            <div className="title">Zu Hause</div>
            <div className="text">
              Die Anwendungsmöglichkeiten der 90.10.-Produkte sind vielfältig.
              In der Küche ist ihr Einsatz so sinnvoll wie im Schlafbereich, im
              Kinderzimmer, im Home-Office sowie bei der Tier- und
              Pflanzenpflege.
            </div>
          </div>
        </BackgroundImage>
      </ContentRowComponent>

      <ContentRowComponent>
        <div className="content-row-box-wrapper">
          <BoxComponent icon={<SmogIcon />} type="small">
            <div className="box-title small">Kraft gegen E-Smog</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent invers theme="action" title="Mehr erfahren" />
          </BoxComponent>

          <BoxComponent icon={<WaterIcon />} type="small">
            <div className="box-title small">Vitalisiertes Wasser</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent invers theme="action" title="Mehr erfahren" />
          </BoxComponent>

          <BoxComponent icon={<FoodIcon />} type="small">
            <div className="box-title small">Strukturierte Lebensmittel</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent invers theme="action" title="Mehr erfahren" />
          </BoxComponent>
        </div>
      </ContentRowComponent>

      <StaticImage
        src="../assets/images/i-am-energy.jpg"
        alt="I am energy"
        layout="fullWidth"
        placeholder="blurred"
      />

      <ContentRowComponent className="column-wrapper">
        <div className="max-sized-word">Mehr</div>
        <div className="detail-big-text">Konzentration, Leistung, Energie</div>
      </ContentRowComponent>

      <ContentRowComponent className="column-wrapper">
        <BackgroundImage
          className={"text-image-wrapper"}
          fluid={jobBgImageData as any}
        >
          <div
            className="filter-element"
            style={{ background: "#06AC32 0% 0% no-repeat padding-box" }}
          />
          <div className="content-wrapper">
            <div className="title">Beruf</div>
            <div className="text">
              Im beruflichen Alltag sind wir geistig und/oder körperlich
              besonders gefordert. Eine ausgewogene Ernährung, Bewegung und
              Entspannung genügen oft nicht als Vorbereitung. Mit
              Frequenztechnologie von 90.10. hebst Du Deine Leistungsfähigkeit
              auf ein neues Level.
            </div>
          </div>
        </BackgroundImage>
      </ContentRowComponent>

      <ContentRowComponent>
        <div className="content-row-box-wrapper">
          <BoxComponent
            icon={<BurnoutIcon />}
            borderColor="#06AC32"
            type="small"
          >
            <div className="box-title small">Burnout vermeiden</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#06AC32"
            />
          </BoxComponent>

          <BoxComponent icon={<FocusIcon />} borderColor="#06AC32" type="small">
            <div className="box-title small">Fokussiert arbeiten</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#06AC32"
            />
          </BoxComponent>

          <BoxComponent
            icon={<CreativityIcon />}
            borderColor="#06AC32"
            type="small"
          >
            <div className="box-title small">Kreativität ausleben</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#06AC32"
            />
          </BoxComponent>
        </div>
      </ContentRowComponent>

      <ContentRowComponent className="column-wrapper first-z-index">
        <BackgroundImage
          className={"text-image-wrapper"}
          fluid={sportBgImageData as any}
        >
          <div
            className="filter-element"
            style={{ background: "#C1007E 0% 0% no-repeat padding-box" }}
          />
          <div className="content-wrapper">
            <div className="title">Sport</div>
            <div className="text">
              Regelmäßiges Training steigert das Leistungsvermögen.
              Nichtsdestotrotz erreicht jeder Sportler irgendwann einen Punkt,
              ab dem nicht mehr drin zu sein scheint. Mit der
              90.10.-Frequenztechnologie sind neue Erfolge im Sport möglich.
            </div>
          </div>
        </BackgroundImage>
      </ContentRowComponent>

      <ContentRowComponent circleConfig={circleConfigs[3]}>
        <div className="content-row-box-wrapper">
          <BoxComponent
            icon={<PerformanceIcon />}
            borderColor="#C1007E"
            type="small"
          >
            <div className="box-title small">Leistung steigern</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#C1007E"
            />
          </BoxComponent>

          <BoxComponent
            icon={<AusdauerIcon />}
            borderColor="#C1007E"
            type="small"
          >
            <div className="box-title small">Ausdauer verbessern</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#C1007E"
            />
          </BoxComponent>

          <BoxComponent
            icon={<MuscleIcon />}
            borderColor="#C1007E"
            type="small"
          >
            <div className="box-title small">Muskelkater verhindern</div>
            <div className="flex-grow-1 box-text small">
              Mache dir einen populären Nebeneffekt der Quanten-energie zunutze
              und stärke die Selbstheilungskräfte Deiner Patienten. Wunden
              können drei Mal schneller heilen als mit den modernsten bekannten
              Therapien und Mitteln.
            </div>
            <ButtonComponent
              invers
              theme="action"
              title="Mehr erfahren"
              bgColor="#C1007E"
            />
          </BoxComponent>
        </div>
      </ContentRowComponent>

      <ContentRowComponent background="#F8F8F8" maxWidthWrapper>
        <StaticImage
          className="smaller-image"
          src="../assets/images/cube-shop.png"
          alt="Virtual Cube"
          placeholder="blurred"
        />
        <div className="text-content-wrapper">
          <div className="orange-box-title width-260">90.10. CUBE</div>
          <div className="text-content-wrapper--headline">
            Willst Du eigene Energie&shy;träger herstellen?
          </div>
          <div className="text-content-wrapper--content">
            Mach Dir Deine Welt, wie sie Dir gefällt! Mit dem 90.10.-CUBE hast
            Du alle Möglichkeiten: „Brenne“ Quantenenergie und Frequenzen Deiner
            Wahl in genau die Gegenstände, in denen Du sie brauchst. Das kann
            vieles sein: Geschirr, Kleidung, Schmuck, Kosmetik, Technik,
            Pflanzen etc. Nach dem Brennvorgang speichern die Gegenstände
            dauerhaft Quantenenergie (+ Frequenzen) und geben diese an Dich oder
            Deine Umwelt ab.
          </div>
          <ButtonComponent title="Mehr Erfahren" invers />
        </div>
      </ContentRowComponent>

      <ContentRowComponent order={{ mobile: [2, 1] }} maxWidthWrapper>
        <div className="text-content-wrapper second-item">
          <div className="orange-box-title width-320">90.10. MedBed</div>
          <div className="text-content-wrapper--headline">
            Quanten&shy;frequenz- Medizin mit wissen&shy;schaftlichem Nachweis
          </div>
          <div className="text-content-wrapper--content">
            Mach Dir Deine Welt, wie sie Dir gefällt! Mit dem 90.10.-CUBE hast
            Du alle Möglichkeiten: „Brenne“ Quantenenergie und Frequenzen Deiner
            Wahl in genau die Gegenstände, in denen Du sie brauchst. Das kann
            vieles sein: Geschirr, Kleidung, Schmuck, Kosmetik, Technik,
            Pflanzen etc. Nach dem Brennvorgang speichern die Gegenstände
            dauerhaft Quantenenergie (+ Frequenzen) und geben diese an Dich oder
            Deine Umwelt ab.
          </div>
          <ButtonComponent title="Mehr Erfahren" invers />
        </div>
        <StaticImage
          src="../assets/images/medbed_full.png"
          alt="MedBed"
          placeholder="blurred"
        />
      </ContentRowComponent>

      <ContentRowComponent className="column-wrapper">
        <div className="text-image-wrapper">
          <div className="background-img">
            <StaticImage
              className="image-wrapper"
              src="../assets/images/mobiles_medbed.jpg"
              alt="Mobiles MedBed"
              placeholder="blurred"
            />
          </div>
          <div className="text-content-wrapper left-50">
            <div className="orange-box-title width-465">
              90.10. MedBed Mobile
            </div>
            <div className="text-content-wrapper--headline">
              Hier kommt eine aussage&shy;kräftige Headline hin
            </div>
            <div className="text-content-wrapper--content">
              Mach Dir Deine Welt, wie sie Dir gefällt! Mit dem 90.10.-CUBE hast
              Du alle Möglichkeiten: „Brenne“ Quantenenergie und Frequenzen
              Deiner Wahl in genau die Gegenstände, in denen Du sie brauchst.
              Das kann vieles sein: Geschirr, Kleidung, Schmuck, Kosmetik,
              Technik, Pflanzen etc. Nach dem Brennvorgang speichern die
              Gegenstände dauerhaft Quantenenergie (+ Frequenzen) und geben
              diese an Dich oder Deine Umwelt ab.
            </div>
            <ButtonComponent title="Mehr Erfahren" invers />
          </div>
        </div>
      </ContentRowComponent>

      <ContentRowComponent className="column-wrapper">
        <div className="text-image-wrapper">
          <div className="background-img">
            <StaticImage
              className="image-wrapper"
              src="../assets/images/9010-forschung.jpg"
              alt="Mobiles MedBed"
              placeholder="blurred"
            />
          </div>
          <div className="text-content-wrapper max-50 white">
            <div className="orange-box-title width-405">90.10. Forschung</div>
            <div className="text-content-wrapper--headline">
              100.000
              <br />
              Forschung
            </div>
            <div className="text-content-wrapper--content">
              Die Methode der 90.10.-Quantenverschränkung und die Funktion des
              90.10. MedBeds wurden mit wissenschaftlichen Methoden im Labor
              überprüft und bestätigt. Erlebe jetzt deine erste 90.10.
              MedBed-Session und nutze die wohl stärkste
              Selbstheilungstechnologie der heutigen Zeit.
            </div>
            <ButtonComponent title="Mehr Erfahren" invers />
          </div>
        </div>
      </ContentRowComponent>

      <ContentRowComponent
        className="minimal-z-index"
        circleConfig={{
          color: "#f89a26",
          radius: 2000,
          horiz: -400,
          weight: 9,
          top: -200,
          active: true,
        }}
      >
        <SeperatorComponent height={100} />
      </ContentRowComponent>
      <StaticImage
        src="../assets/images/people.jpg"
        alt="People"
        layout="fullWidth"
        placeholder="blurred"
      />

      <ContentRowComponent className="column-wrapper no-padding">
        <div className="text-image-wrapper">
          <div className="background-img">
            <StaticImage
              className="image-wrapper"
              src="../assets/images/kontakt.jpg"
              alt="Mobiles MedBed"
              placeholder="blurred"
            />
          </div>
          <div className="text-content-wrapper white center-text max-width-300">
            <div className="text-content-wrapper--headline">
              Du möchtest mehr erfahren?
            </div>
            <div className="text-content-wrapper--content">
              Trage Dich jetzt in unsere Mailingliste ein. Du erhältst kostenlos
              unseren neuen QuantumGuide.
            </div>
            <KlicktippComponent />
          </div>
        </div>
      </ContentRowComponent>
    </LayoutComponent>
  );
};

export default IndexPage;

export const query: StaticQueryDocument = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    medicineParallaxImage: file(relativePath: { eq: "9010-medizin.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeParallaxImage: file(relativePath: { eq: "9010-zuhause.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jobParallaxImage: file(relativePath: { eq: "9010-beruf.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sportParallaxImage: file(relativePath: { eq: "9010-sport.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
