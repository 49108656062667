import React from "react";
import "./BoxComponent.style.scss";
import { BoxComponentProps } from "./BoxComponent.types";

const BoxComponent: React.FC<BoxComponentProps> = ({
  children,
  icon,
  title,
  borderColor = "#f89a26",
  type = "normal",
}) => {
  return (
    <div className="box-component">
      <div
        className={[
          "box-component--wrapper",
          type === "small" ? "small-box" : undefined,
        ].join(" ")}
        style={{ borderColor: borderColor }}
      >
        {icon && (
          <div
            className="icon"
            style={{ borderColor: borderColor, background: borderColor }}
          >
            {icon}
          </div>
        )}
        {title && <div className="title">{title}</div>}
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default BoxComponent;
