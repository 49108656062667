import React, { useState } from "react";
import { KlicktippComponentProps } from "./KlicktippComponent.types";
import "./KlicktippComponent.style.scss";
import { Script } from "gatsby";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import InputComponent from "../InputComponent/InputComponent";

const KlicktippComponent: React.FC<KlicktippComponentProps> = ({}) => {
  // TODO STYLING: https://app.9010.com/ein-klick-fehlt-noch
  const apiKey: string = "44eaz31jpz8z4344";
  const [formData, setFormData] = useState<{
    firstName: string;
    lastName: string;
    mail: string;
  }>({ firstName: "", lastName: "", mail: "" });

  return (
    <div className="klicktipp-component">
      <div id="form-176900-wrapper">
        <form
          id="ktv2-form-176900"
          acceptCharset="UTF-8"
          target="_top"
          method="post"
          action="https://www.klick-tipp.com/api/subscriber/signin.html"
        >
          <input
            type="hidden"
            id="FormField_ApiKey"
            name="apikey"
            value={apiKey}
          />
          <input
            type="hidden"
            id="FormField_Digit"
            name="fields[fieldDigit]"
            value=""
          />
          <InputComponent
            id="FormField_FirstName"
            name="fields[fieldFirstName]"
            value={formData.firstName}
            placeholder="Vorname"
            onChange={(value) => setFormData({ ...formData, firstName: value })}
          />
          <InputComponent
            id="FormField_LastName"
            name="fields[fieldLastName]"
            value={formData.lastName}
            placeholder="Nachname"
            onChange={(value) => setFormData({ ...formData, lastName: value })}
          />
          <InputComponent
            id="FormField_EmailAddress"
            name="email"
            type="email"
            value={formData.mail}
            placeholder="E-Mail-Adresse"
            onChange={(value) => setFormData({ ...formData, mail: value })}
          />
          <ButtonComponent
            title="Mehr erfahren"
            theme="outline"
            invers
            type="submit"
            id="FormSubmit"
            name="FormSubmit"
          />
        </form>
      </div>

      <Script type="text/javascript" src="/js/switch.js" />
      <Script type="text/javascript" src="/js/protect.js" />
    </div>
  );
};

export default KlicktippComponent;
