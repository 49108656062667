import React from "react";
import { VideoComponentProps } from "./VideoComponent.types";
import "./VideoComponent.styles.scss";

const VideoComponent: React.FC<VideoComponentProps> = ({
  src,
  maxHeight = 1600,
  minHeight,
  poster,
}) => (
  <div className="video-component" style={{ maxHeight: `${maxHeight}px` }}>
    <video
      autoPlay
      playsInline
      poster={poster}
      muted
      loop
      controls={false}
      className="autplay-playback"
    >
      <source src={src} type="video/mp4" />
    </video>
    <video
      className="hidden-height-playback"
      style={{ minHeight: minHeight ? `${minHeight}px` : undefined }}
    >
      <source src={src} type="video/mp4" />
    </video>
  </div>
);

export default VideoComponent;
